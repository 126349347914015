export default {
  // 訂單狀態
   STATUS_CREATED : 'created', // 新訂單
   STATUS_APPLY : 'apply', // 已接受
   STATUS_FINISHED : 'finished', // 已完成
   STATUS_SENDED : 'sended', // 已出貨
   STATUS_CANCEL : 'canceled', // 取消
   STATUS_REJECT : 'reject', // 拒絕
   STATUS_REQUEST_RETURN : 'request_return', // 退貨申請
}
