

export default {
  props: {
    defaultKey: String,
    propsFormKey: String,
    isSubmit: Boolean,
    propsValue: [Array, Object],
    props: {
      type: Object,
      default: () => null
    },
  },
  data: () => ({
    formKey: null,
  }),
  watch: {
    propsFormKey: {
      immediate: true,
      handler() {
        this.formKey = this.propsFormKey ? this.propsFormKey : this.defaultKey;
      }
    },
    isSubmit: {
      immediate: true,
      handler() {
        // this.isSubmit = this.$parent.isSubmit
      }
    }
  },

}
